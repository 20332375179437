
const CONTRACT_FIPS_STATUS = {
  UNISSUE: 99, // 未発行
  UNOPEN: 0, // 交付待ち
  OPEN: 1, // 交付済
  DELIVERY: 10, // 引渡済
  EXPIRE: 11, // 解除
  CANCEL: 12, // 取消
}

// FIPS保証証書終了タイプ（APIに渡す値）
const FIPS_WARRANTIES_COMPLETE_TYPE = {
  DELIVERY: 1, // 引渡
  EXPIRE: 2, // 解除
  CANCEL: 3, // 取消
}

const FIPS_OPERATION_LIST = {
  ISSUE_CSV_UPLOAD: 0,
  ISSUE: 1,
  OPEN: 2,
  DELIVERY: 3
}

// S:文字、N:数値、D:日付、R:改行あり文字列
const COLUMN_TYPE = {
  STRING: 'S',
  TEXT: 'R',
  NUMBER: 'N',
  DATE: 'D',
}

export default {
  COLUMN_TYPE,
  DOCUMENT: {
    NO_SIGN: 0,
    SIGNED: 1,
  },
  // 契約グループ一覧:手付金等保証用表示中 最大選択値
  FIPS_MAX_SELECT_COUNT: 200,
  CONTRACT_FIPS_STATUS,
  FIPS_STATUS_FILTER: [
    { value: -1, text: 'すべて' },
    { value: CONTRACT_FIPS_STATUS.UNISSUE, text: '未発行' },
    { value: CONTRACT_FIPS_STATUS.UNOPEN, text: '交付待ち' },
    { value: CONTRACT_FIPS_STATUS.OPEN, text: '交付済' },
    { value: CONTRACT_FIPS_STATUS.DELIVERY, text: '引渡報告済' },
    { value: CONTRACT_FIPS_STATUS.EXPIRE, text: '解除報告済' },
    { value: CONTRACT_FIPS_STATUS.CANCEL, text: '取消報告済' },
  ],
  FIPS_WARRANTIES_COMPLETE_TYPE,
  FIPS_OPERATION_LIST,
  FIPS_OPERATION_TYPE_LIST: [
    { value: FIPS_OPERATION_LIST.ISSUE_CSV_UPLOAD, text: '登録用CSVアップロード', title: '発行依頼情報登録' },
    { value: FIPS_OPERATION_LIST.ISSUE, text: '発行', title: '保証証書一括発行' },
    { value: FIPS_OPERATION_LIST.OPEN, text: '交付', title: '保証証書一括交付' },
    { value: FIPS_OPERATION_LIST.DELIVERY, text: '引渡報告', title: '一括保証終了報告（引渡）' },
  ],
  // 手付金等保証証書の交付時の注意文言
  FIPS_OPEN_WARNING_TEXT: '保証証書の電子交付について、買主の事前承諾を得ていることをご確認ください。',
  // CSV
  FIPS_ISSUE_CSV: {
    HEADER_COUNT: 2,
    MAX_ITEM_COUNT: 200,
    MAX_ERROR_COUNT: 5,
  },

  EXTRA_INFO: {
    INPUT_TYPE: [
      { value: COLUMN_TYPE.STRING, text: '文字列' },
      { value: COLUMN_TYPE.TEXT, text: '文字列（改行あり）' },
      { value: COLUMN_TYPE.NUMBER, text: '数値' },
      { value: COLUMN_TYPE.DATE, text: '日付' },
    ],
    DISABLED: [
      { value: 0, text: '活性', color: 'primary lighten-4', textColor: 'primary' },
      { value: 1, text: '非活性', color: 'orange lighten-4', textColor: 'orange' },
      { value: -1, text: '非表示', color: 'danger lighten-4', textColor: 'danger' },
    ]
  },
  TAG_INFO: {
    CATEGORY: [
      { value: 'contract_info', text: '契約情報' },
      { value: 'm_article', text: '物件情報' },
      { value: 'm_homebuilder', text: '宅建士情報' },
      { value: 'm_partner', text: '関係者情報' },
      { value: 'goods_service', text: '物品サービス' },
      { value: 'sales_agreement_history', text: '原契約情報' },
      { value: 'sales_agreement_history_document', text: '原契約情報（契約ドキュメント情報）' },
      { value: 'sales_agreement_history_customer', text: '原契約情報（個人情報）' },
      { value: 'sales_agreement_history_document_partner', text: '原契約情報（契約ドキュメント関係者情報）' },
      { value: 'sales_agreement_history_contract', text: '原契約情報（契約情報）' },
      { value: 'contract_extra_info', text: '契約拡張情報' },
      { value: 'article_extra_info', text: '物件拡張情報' },
      { value: 'customer_info', text: '個人情報' },
      { value: 'customer_info_before', text: '個人情報（変更前）' },
      { value: 'customer_info_after', text: '個人情報（変更後）' },
      { value: 'customer_info_in', text: '個人情報（追加）' },
      { value: 'customer_info_out', text: '個人情報（削除）' },
    ],
    PRINT_TYPE: [
      { value: null, text: '変換なし' },
      { value: 'C', text: '数字（カンマ区切り）' },
      { value: 'N', text: '数字' },
      { value: 'D', text: '日付' },
    ],
  }
}
