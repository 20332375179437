const EMPTY = {
  original_article_id: '',
  article_name: '',
  sales_end_date: '',
  sales_start_date: '',
  article_manager: {
    id: '',
    original_user_id: '-',
    user_name: '-',
    department_name: '-',
    mail_address: '-',
  },
}

export default {
  state: {
    article: null,
  },

  getters: {
    getArticle: (state) => state.article,
    isEnableFipsTenant: (state) => state.article?.fips_tenant_id !== null
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveArticle(state, data) {
      state.article = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchArticle({ commit }, requestParams) {
      const { articleId, include_deleted } = requestParams
      const params = {}
      if (include_deleted !== undefined) {
        params.include_deleted = include_deleted
      }
      await this.$axios.get(`/articles/${articleId}`, { params }).then(function (response) {
        commit('saveArticle', response.data?.body?.data)
      })
    },

    initArticle({ commit }) {
      commit('saveArticle', EMPTY)
    },

    async addArticle({ commit }, payload) {
      const { params } = payload
      return await this.$axios.post('/articles', params)
    },

    async updateArticle({ commit }, payload) {
      const { article_id, params } = payload
      return await this.$axios.put('/articles/' + article_id, params)
    },
  },
}
