
import { mapGetters, mapActions /* mapMutations */ } from 'vuex'
import CONST from '@/data/const'

export default {
  name: 'CustomerAppBar',

  components: {
    CustomerMenuDrawer: () => import('@/components/organisms/CustomerMenuDrawer'),
    ButtonIcon: () => import('@/components/atoms/ButtonIcon'),
    MusubellLogoImg: () => import('@/components/atoms/MusubellLogoImg'),
  },

  props: {},

  data() {
    return {
      CONST,
    }
  },

  fetch({ store }) {
    store.dispatch('customer/customerAccount/fetchAccount')
  },

  computed: {
    ...mapGetters({
      getThemeMode: 'themeConfig/getThemeMode',
      getAccount: 'customer/customerAccount/getAccount',
    }),
  },
  async mounted() {
    // サイドメニューアイテムの[手付金等保証]を出し分けのため、ログインアカウントが代理人じゃない場合、手付金等保証賞を取得する
    if (this.getAccount.type !== CONST.ACCOUNT_TYPE.CUSTOMER_AGENT) {
      await this.fetchFipsWarranties()
    }
  },
  methods: {
    ...mapActions({
      setShowHide: 'customer/customerMenuDrawer/setShowHide',
      fetchFipsWarranties: 'customer/fips/fetchFipsWarranties'
    }),
    onClickMenuBtn() {
      this.setShowHide(true)
    },
  },
}
