import CONST from '@/data/const'

export default {
  state: {
    articleDocuments: [],
    articleId: null
  },

  getters: {
    getArticleDocuments: (state) => {
      return state.articleDocuments
    },
    getDocument: (state) => (contractId, documentId) => {
      documentId = parseInt(documentId, 10)
      return state.articleDocuments.find((document) => {
        return document.contract_info_id === contractId && document.id === parseInt(documentId, 10)
      })
    },
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveArticleDocuments(state, data) {
      state.articleDocuments = data
    },
    saveArticleId(state, data) {
      state.articleId = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchAritcleDocuments({ commit, state }, params) {
      const { articleId, contractId } = params

      commit('saveArticleId', articleId)

      let url = `/contract_document/article/${articleId}`
      if (contractId) {
        url += `?contract_id=${contractId}`
      }

      await this.$axios.get(url).then(function (response) {
        let documents = []
        if (response.data?.body?.data) {
          documents = response.data.body.data
        }

        // リクエスト中に物件IDが切り替わっている場合を考慮
        // 物件IDが切り替わっていない場合飲みstate.articleDocumentsを更新する
        if (state.articleId === articleId) {
          commit('saveArticleDocuments', documents)
        }
      })
    },

    // 契約に紐づく締結済み書類zip作成依頼
    async createConcluedContractDocument({ commit }, params) {
      const { contract_id } = params
      return await this.$axios.post(
        `/contract_documents/concluded/${contract_id}`,
        null,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

    // 契約に紐づく締結済み書類zipダウンロード
    async downloadConcluedContractDocument({ commit }, params) {
      const { contract_id, execution_arn } = params
      return await this.$axios.get(
        `/contract_documents/concluded/${contract_id}?execution_arn=${execution_arn}`,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

    // 物件に紐づく締結済み書類zip作成依頼
    async createConcluedArticleContractDocument({ commit }, params) {
      const { article_id } = params
      return await this.$axios.post(
        `/contract_documents/concluded_article/${article_id}`,
        null,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

    // 物件に紐づく締結済み書類zipダウンロード
    async downloadConcluedArticleContractDocument({ commit }, params) {
      const { article_id, execution_arn } = params
      return await this.$axios.get(
        `/contract_documents/concluded_article/${article_id}?execution_arn=${execution_arn}`,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

  },
}
