/*
 * 集計（手付金等保証）情報ストア
 */

export default {
  state: {
    aggregationDepositList: [],
  },

  getters: {
    getAggregationDepositList: state => state.aggregationDepositList,
  },

  mutations: {
    saveAggregationDepositList(state, data) {
      state.aggregationDepositList = data
    },
  },

  actions: {
    // 手付金等証保証書発行数取得
    async fetchFipsWarrantyCounts({ commit }, params) {
      const res = await this.$axios.get(`/fips_warranty_counts?month=${params.month}`)
      commit('saveAggregationDepositList', res.data?.body?.data || [])
    },
  },
}
