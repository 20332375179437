
export default {
  state: {
    contract: {},
    detail: [],
    fips_warranty_issue: {},
    fips_warranty_list: [],
  },

  getters: {
    getContract: state => state.contract,
    getContractCusomers: state => (state.contract ? state.contract.customers : []),
    getContractCusomerByOrder: state => (order) => {
      if (!state.contract.customers) {
        return null
      }
      return state.contract.customers.find((customer) => {
        return customer.co_owner_order === order
      })
    },
    getContractItemById: state => (id) => {
      if (!state.contract.detail) {
        return null
      }
      return state.contract.detail.find((contractItem) => {
        return contractItem.id === id
      })
    },
    getContractDetail: state => state.detail,
    getFipsWarrantyIssue: state => state.fips_warranty_issue,
    getFipsWarrantyList: state => state.fips_warranty_list,
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveContractCustomer(state, payload) {
      state.contract.customers = payload
    },

    saveContractDetail(state, payload) {
      state.detail = payload
    },

    saveFipsWarrantyIssue(state, payload) {
      state.fips_warranty_issue = payload
    },

    saveFipsWarrantyList(state, payload) {
      state.fips_warranty_list = payload
    }
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {

    // 契約詳細情報一覧取得
    async fetchContractDetail({ commit }, params) {
      const { contract_id } = params

      // API:契約詳細情報一覧取得
      await this.$axios.get(`/contract_infos/detail/${contract_id}`, params).then((response) => {
        let detail = []

        const res = response.data?.body?.data
        if (res && Object.keys(res).length > 0) {
          detail = response.data?.body?.data
        }

        commit('saveContractDetail', detail)
      })
    },

    // 契約者情報CSVダウンロード
    async downloadContractCustomers({ commit }, articleId) {
      // API:顧客情報CSV出力データ取得
      return await this.$axios.get(`/contract_customers/csv_output_data/${articleId}`, { responseType: 'blob' })
    },

    // 契約者情報CSVアップロード
    async uploadContractCustomers({ commit }, params) {
      const { articleId, formData } = params

      // API:顧客情報CSVインポート
      return await this.$axios.post(
        `/csv_import/customer/${articleId}`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
    },

    // 契約詳細情報CSVダウンロード
    async downloadContractDetail({ commit }, articleId) {
      // API:契約グループCSV出力データ取得
      return await this.$axios.get(`/contract_group/csv_output_data/${articleId}`, { responseType: 'blob' })
    },

    // 契約詳細情報CSVアップロード
    async uploadContractDetail({ commit }, params) {
      const { articleId, formData } = params

      // API:契約情報CSVインポート
      return await this.$axios.post(
        `/csv_import/contract_group/${articleId}`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
    },

    setContractCustomer({ commit }, payload) {
      commit('saveContractCustomer', payload)
    },

    async setContractStatus({ commit }, params) {
      const { contract_id, contract_status } = params
      return await this.$axios.put(`/contract_infos/${contract_id}`, { contract_status })
    },

    // 契約詳細情報更新
    async updateContracDetail({ commit }, params) {
      const { contract_id, items } = params
      return await this.$axios.put(`/contract_infos/detail/${contract_id}`, { items })
    },

    // FIPS保証証書発行依頼情報取得
    async fetchFipsWarrantyIssue({ commit }, params) {
      const { contract_info_id } = params
      const res = await this.$axios.get(`/contracts/${contract_info_id}/fips_warranty_issue`)
      commit('saveFipsWarrantyIssue', res.data?.body?.data || {})
    },

    // FIPS保証証書発行依頼情報更新
    async updateFipsWarrantyIssue({ commit }, params) {
      const { contract_info_id, reqParams } = params
      const config = params?.config || null
      return await this.$axios.put(`/contracts/${contract_info_id}/fips_warranty_issue`, reqParams, config)
    },

    // FIPS保証証書発行
    async issueFipsWarranty({ commit }, params) {
      const { contract_info_id } = params
      const config = params?.config || null
      return await this.$axios.post(`/contracts/${contract_info_id}/fips_warranties`, null, config)
    },

    // FIPS保証証書交付
    async openFipsWarranty({ commit }, params) {
      const { contract_info_id, fips_warranty_info_id } = params
      const config = params?.config || null
      return await this.$axios.put(`/contracts/${contract_info_id}/fips_warranties/${fips_warranty_info_id}/open`, null, config)
    },

    // FIPS保証証書終了
    async closeFipsWarranty({ commit }, params) {
      const { contract_info_id, reqParams } = params
      const config = params?.config || null
      return await this.$axios.put(`/contracts/${contract_info_id}/fips_warranties`, reqParams, config)
    },

    // FIPS保証証書情報一覧取得
    async fetchFipsWarranties({ commit }, params) {
      const { contract_info_id } = params
      const res = await this.$axios.get(`/contracts/${contract_info_id}/fips_warranties`)
      commit('saveFipsWarrantyList', res.data?.body?.data || [])
    },

    // 手付金等保証証書PDF取得
    async getFipsWarrantyPdf({ commit }, params) {
      const { contract_info_id, fips_warranty_info_id } = params
      const res = await this.$axios.get(`/contracts/${contract_info_id}/fips_warranties/${fips_warranty_info_id}/pdf`, { responseType: 'blob' }, {
        headers: {
          'content-type': 'application/pdf',
        },
      })
      return res
    },

    // 契約者情報追加
    async addCustomer({ commit }, params) {
      const { contract_info_id, reqParams } = params
      const config = params?.config || null
      return await this.$axios.post(`/contracts/${contract_info_id}/customers`, reqParams, config)
    },
  }
}
