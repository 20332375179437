
export const MODE = {
  DEFAULT_LIST: 0,
  FIPS_INFO: 1,
}

const base = {
  page: 1,
  sortBy: [],
  sortDesc: [],
  descending: false,
  searchText: '',
  filterStatus: -1,
  pageCount: 0,
  currentMode: MODE.DEFAULT_LIST,
  fipsfilterStatus: -1,
}

export default {
  state: {
    articleId: null,
    pagination: base,
  },

  getters: {
    getPagination: (state) => state.pagination,
    getCurrentMode: (state) => state.pagination.currentMode
  },

  mutations: {
    saveArticleId(state, articleId) {
      // 物件IDが変わった場合はフィルタやページネーションをリセットする
      if (state.articleId !== articleId) {
        state.pagination = base
      }
      state.articleId = articleId
    },

    savePagination(state, data) {
      state.pagination = data
    },

  },

  actions: {
    setArticleId({ commit }, articleId) {
      commit('saveArticleId', articleId)
    },

    setPagination({ commit, state }, payload) {
      commit('savePagination', { ...state.pagination, ...payload })
    },

    resetPagination({ commit }) {
      commit('savePagination', base)
    },
  },

}
