import moment from 'moment'
import CONST from '~/data/const'

// 日付のフォーマット
const format = (date) => date ? moment(date).format(CONST.DATE_FORMAT) : '' // 'YYYY/MM/DD'
const formatInput = (date) => date ? moment(date).format(CONST.DATE_FORMAT_INPUT) : '' // 'YYYY-MM-DD'
const formatTime = (date) => date ? moment(date).format(CONST.DATE_FORMAT_TIME) : '' // 'YYYY/MM/DD HH:mm'
const formatMonth = (date) => date ? moment(date).format(CONST.DATE_FORMAT_MONTH) : '' // 'YYYY/MM'
const INVALID_DATE = 'Invalid date'
const currentMonth = () => moment().format(CONST.DATE_FORMAT_PARAMS) // 'YYYYMM'
const formatParams = (date) => date ? moment(date).format(CONST.DATE_FORMAT_PARAMS) : '' // 'YYYYMM'
const formatParamsDay = (date) => date ? moment(date).format(CONST.DATE_FORMAT_PARAMS_DAY) : '' // 'YYYYMMDD'

export default {
  format,
  formatInput,
  formatTime,
  formatMonth,
  INVALID_DATE,
  currentMonth,
  formatParams,
  formatParamsDay,
}
