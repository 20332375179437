// 契約グループ
export default {
  state: {
    contracts: [],
    articleId: null,
  },

  getters: {
    getContractGroups: (state) => {
      return state.contracts
    },
    getContractGroup: (state) => (id) => {
      id = parseInt(id, 10)
      return state.contracts.find((contract) => {
        return contract.id === id
      })
    },
  },

  mutations: {
    saveContractGroups(state, data) {
      state.contracts = data
    },
    saveArticleId(state, data) {
      state.articleId = data
    },
  },

  actions: {
    // 契約グループ一覧取得
    async fetchContractGroups({ commit, state }, articleId) {
      commit('saveArticleId', articleId)

      await this.$axios.get(`/contract_group/${articleId}`).then(function (response) {
        let contracts = []
        if (response.data?.body?.data) {
          contracts = response.data.body.data
        }

        // リクエスト中に物件IDが切り替わっている場合を考慮
        // 物件IDが切り替わっていない場合飲みstate.contractsを更新する
        if (state.articleId === articleId) {
          commit('saveContractGroups', contracts)
        }
      })
    },

    // FIPS保証証書発行依頼情報の一括（CSV）ダウンロード
    async downloadFipsWarrantyIssuesCsv({ commit }, params) {
      const { contract_info_id_list } = params
      return await this.$axios.get(`/contract_group/fips_warranty_issues/csv?contract_info_id_list=${contract_info_id_list}`, { responseType: 'blob' })
    }
  },
}
